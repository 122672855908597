import React from 'react'
import Banner from './Banner'
import Menu from './Menu'
import ProductoPrincipal from './ProductoPrincipal'



const Inicio = () => {
  return (
    <div>       
        <Menu/>
        <Banner/> 
                 
    </div>
  )
}

export default Inicio